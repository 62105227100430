
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import { setCCBId, setCCBSchoolId } from '@/utils/cookies'

@Component({
  name: 'ccblife',
  mixins: [GlobalMixin]
})
export default class PartnerLoginPage extends Vue {
    @Provide() public ccbParam: string = '';

    public async created () {
      console.log('关爱通CCBLIFE')
      this.ccbParam = getCurrentQuery('ccbParam')
      setCCBId(this.ccbParam)
      this.doAuth()
    }

    @Emit()
    public setUrl () {
      let { ccbParam = '' } = this.$route.query
      const str = ccbParam
      location.replace(`ccblife?redirect=https%3A%2F%2Fm.benlai.com&source=11&ccbParam=${str}`)
    }

    @Emit()
    public async doAuth () {
      const data: any = {
        ccbParam: decodeURIComponent(this.ccbParam)
      }
      try {
        const res = await httpHelper.post({
          url: 'authorize/ccbSchoolCard',
          contentType: 'application/x-www-form-urlencoded',
          data: data,
          headers: {
            Authorization: configs.basicAuthorization
          }
        })
        if (res.code === 0) {
          setCCBSchoolId(res.value.extend.ccbSchoolCardUID)
          location.replace(res.value.extend.ccbSchoolCardPage)
        }
      } catch (err) {
        this.goTo(err, 9, 18)
      }
    }
}
